//utils

//nodefinējam requirejs mainīgos
require.config( {
    paths  : {
        jquery: 'vendor/jquery/jquery.min',
        bodymovin: 'vendor/bodymovin/bodymovin',
        qwest    : 'vendor/qwest/qwest.min',
        scrollTrigger : 'vendor/scrollTrigger/ScrollTrigger.min'
    },
    urlArgs: 'r=' + STORE.revision
} );

require( [ 'helpers/utils', 'helpers/svg-fix', 'jquery', 'bodymovin', 'qwest', 'scrollTrigger' ], function ( h, svg, $, bodymovin, qwest, scrollTrigger ) {

    var $app;

    var app = {
        _env           : null,
        _picture       : false,
        _cutsTheMustard: false,
        anim1           : null,
        anim2           : null,
        anim3           : null,
        anim4           : null,
        anim5           : null,
        init           : function () {
            $app = this;
            this._setEnv();
            this.watchers();

            this.cutTheMustard();
//            this.supportsPicture();
            this.initSprites();
            //
            ////section specific
            this.maps();
            //
            this.menu();
            this.requestDemo();
            this.scrollAnimation();
            this.validateForm();
            h.addGaTracking();
            // can also be used this for events not strictly coupled with ui elements
            //h.trackGAEvent( 'category', 'action');
        },
        watchers       : function () {
            //
            h.addEvent( window, 'resize', function () {
                $app._setEnv();
            } );

        },
        cutTheMustard  : function () {
            if ( document.querySelector !== undefined &&
                window.localStorage !== undefined &&
                window.addEventListener !== undefined ) {
                this._cutsTheMustard = true;
            }

        },
        initSprites : function() {
            var params = {}, $this = this, $list = $('.features__detailed__list li');
            if(!$list.length) return false;
            for(var i = 1; i <= 5; i++) {
                params = {
                    container: document.getElementById('sprite'+i),
                    renderer: 'svg',
                    loop: true,
                    autoplay: false,
                    path: 'js/data'+i+'.json'
                };
                this['anim'+i] = bodymovin.loadAnimation(params);
                params = null;
            }
            if ( this._getEnv() == 'simple' ) {
                var trigger = new scrollTrigger({
                    centerVertical : true,
                    toggle: {
                        visible: 'visibleClass',
                        hidden: 'hiddenClass'
                    },
                    addWidth: true
                });
                var callback = function(scrollLeft, scrollTop, width, height){

                        var el = h.any('.visibleClass'),
                            elHid = h.all('.hiddenClass');
                        if(el) {
                            $this[ el.id ].isPaused = false;
                            $this[ el.id ].loop = true;
                        }
                        if(elHid) {
                            for(var x = 0; x < elHid.length; x++)
                            {

                                $this[ elHid[x].id ].loop = false;
                            }
                        }

                };

                trigger.attach(callback);
            }

            $list.on('mouseover', function() {
                var sprite = $(this).find('.features__sprite'),
                    id = sprite.attr('id').replace('sprite', '');
                $this['anim'+id].isPaused = false;
                $this['anim'+id].loop = true;
            });
            $list.on('mouseout', function() {
                var sprite = $(this).find('.features__sprite'),
                    id = sprite.attr('id').replace('sprite', '');
                $this['anim'+id].loop = false;
            });
        },
        menu           : function () {
            //if ( this._getEnv() != 'simple' ) {
            //    return;
            //}
            var $menu       = document.querySelector( '.menu__list' );
            var $menuSwitch = document.querySelector( '.menu__switch' );
            var $menuContainer = document.querySelector( '.menu' );


            h.addEvent( $menuSwitch, 'click', function ( e ) {
                h.pd( e );
                h.toggleClass(this, 'menu__switch--opened');
                h.toggleClass( $menu, 'opened' );
                h.toggleClass( $menuContainer, 'openedParent');
            } );
        },
        scrollAnimation : function () {
            var $logoContainer = document.querySelector('.header__logoContainer');
            if(!document.querySelector('.js-home')) return false;
            h.addEvent(document, 'scroll', function( e ) {
                if(window.pageYOffset > 10) {
                    h.addClass($logoContainer, 'js-transit');
                }
                else
                {
                    h.removeClass($logoContainer, 'js-transit');
                }
            });
        },
        requestDemo : function () {
            var $reqBtn = document.querySelector('.demoLink'),
                $reqBtn2 = document.querySelector('.demoLinkFake'),
                $sbmBtn = document.querySelector('.submitButton'),
                $accordeon = document.querySelector('.accordeon'),
                $form = document.querySelector('.form__request'),
                inputs = h.all('.form__request .form__field input'),
                $success = h.any('.success'), rectObj
                ;
            if (!$reqBtn) return false;
            if ($reqBtn2)
            {
                h.addEvent($reqBtn2, 'click', function (e) {
                    var o;
                    h.pd(e);
                    o = h.getOffset($reqBtn);
                    window.scrollTo(0, o.top + 100);
                    h.addClass($accordeon, 'js-opened');
                    h.toggleClass($reqBtn, ['js-show', 'js-hide']);
                    h.toggleClass($sbmBtn, ['js-show', 'js-hide']);
                    return false;
                });
            }
            h.addEvent($reqBtn, 'click', function(e) {
                h.pd(e);
                h.addClass($accordeon, 'js-opened');
                h.toggleClass($reqBtn, ['js-show', 'js-hide']);
                h.toggleClass($sbmBtn, ['js-show', 'js-hide']);
                return false;
            });

            h.addEvent($form, 'submit', function(e) {
                h.pd(e);
                var data = {}, a;
                for(var i = 0; i < inputs.length; i++) {
                    a = inputs[i].name;
                    data[ inputs[i].name ] = inputs[i].value;
                }
                qwest.post(this.action, data).then(function(xhr, response) {
                    h.addClass($form, 'js-hide');
                    h.removeClass($success, 'js-hide');
                    rectObject = $success.getBoundingClientRect();
                    window.scrollTo(0, window.pageYOffset-Math.abs(rectObject.top)-80);

                });
            });

        },
        validateForm : function() {
          var inputs = h.all('.form__request input[required]'),
              $sbmBtn = document.querySelector('.submitButton'),
              counter = 0,
              phone = h.any('input[name=phone]');
            for(var i = 0; i < inputs.length; i++)
            {
                h.addEvent(inputs[i], 'blur', function(e) {
                    var val = this.value.trim();
                    if(!val.length)
                    {
                        counter = (!counter) ? 0 : --counter;
                    }
                    else {
                        counter = (counter == inputs.length) ? inputs.length : ++counter;
                    }
                    $sbmBtn.disabled = true;
                    if(counter == inputs.length) {
                        $sbmBtn.disabled = false;
                    }
                });
            }
            h.addEvent(phone, 'keydown', function(e) {
                // Allow: backspace, delete, tab, escape, enter and .
                if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190, 48, 57, 16, 107, 189, 109, 192, 187]) !== -1 ||
                        // Allow: Ctrl+A
                    (e.keyCode == 65 && e.ctrlKey === true) ||
                        // Allow: Ctrl+C
                    (e.keyCode == 67 && e.ctrlKey === true) ||
                        // Allow: Ctrl+X
                    (e.keyCode == 88 && e.ctrlKey === true) ||
                        // Allow: home, end, left, right
                    (e.keyCode >= 35 && e.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    h.pd(e);
                }
            });


        },
        _setEnv        : function () {
            this._env = getComputedStyle( document.querySelector( 'body' ) ).zIndex.toString() !== '1' ? 'enhanced' : 'simple';
        },
        _getEnv        : function () {
            return this._env;
        },

      isMapLoaded: false,
      maps: function(){
            var $this = this;
                  if(!this.isMapLoaded) {
                      require(['https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAPS_KEY], function () {

                          var cont = $('#map');
                          var svg = ['<?xml version="1.0" encoding="UTF-8" standalone="no"?>',
                              '<svg width="50px" height="63px" viewBox="0 0 50 63" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
                          '<defs></defs>',
                          '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">',
                              '<g id="KRpinmap">',
                              '<g id="KRPinmap">',
                              '<path d="M50,35.901 L50,5.037 C50,2.255 47.746,0 44.965,0 L5.035,0 C2.254,0 0,2.255 0,5.037 L0,35.901 C0,37.237 0.53,38.518 1.475,39.463 L25,63 L48.525,39.463 C49.47,38.518 50,37.237 50,35.901" id="Fill-1" fill="#F93B00"></path>',
                              '<path d="M22.181,12.0734 L15.699,20.2244 L15.699,12.0734 L11.299,12.0734 L11.299,30.9064 L15.699,30.9064 L15.699,26.9654 L18.518,23.3614 L23.072,30.9064 L28.216,30.9064 L21.431,19.6514 L24.461,15.7914 L24.461,23.3924 L29.024,23.3924 L33.557,30.9064 L38.701,30.9064 L34.057,23.2034 C36.479,22.5644 38.267,20.3564 38.267,17.7324 C38.267,14.6064 35.734,12.0734 32.612,12.0734 L22.181,12.0734 Z M32.027,19.6494 L28.983,19.6494 L28.983,15.8294 L32.027,15.8294 C33.081,15.8294 33.937,16.6854 33.937,17.7394 C33.937,18.7954 33.081,19.6494 32.027,19.6494 Z" id="Combined-Shape" fill="#FFFFFF"></path>',
                              '</g>',
                              '</g>',
                              '</g>',
                              '</svg>'].join('\n');
                          //display map
                          var mapOptions = {
                              center: {lat: 43.6503044, lng: -79.38045950000003},
                              zoom: 17,
                              scrollwheel: false,
                              draggable: ($this._getEnv() == 'simple') ? false : true,
                              disableDefaultUI: true,
                              zoomControl: true
                          };
                          var map = new google.maps.Map(document.getElementById('map'),
                              mapOptions);

                          var marker = new google.maps.Marker({
                              position: mapOptions.center,
                              map: map,
                              icon: { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg) }

                          });

                          $app.isMapLoaded = true;

                      });

                  }


          }


    };

    app.init();


} );
